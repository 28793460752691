.Page_Home{
    width: 100%;
    height: calc(100vh - 40px);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;

    position: relative;
}
.Page_Home .div_data{
    width: 180px;
    height: 90px;
    border: 1px solid #0e4e87;
    border-radius: 6px;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    position: relative;
    cursor: pointer;
}
.Page_Home .div_data:hover{
    transform: scale(0.98);
}
.Page_Home .div_data .qtd{
    width: 90px;
    height: 90px;
    background: #0e6eb4;
    border-radius: 90px;

    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;

    position: absolute;
    top: -40px;

    font-family: 'bebas Neue';
    font-size: 38px;
    line-height: 38px;
}
.Page_Home .div_data .title{
    width: 100%;
    height: 30px;
    background: #0e6eb4;
    color: #ffffff;

    border-top: 1px solid #0e4e87;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    
    display: flex;
    align-items: center;
    justify-content: center;
}