.Page_Client_Details{
    padding-bottom: 70px;
}
.Page_Client_Details .show_img{
    width: auto;
    height: 38px;
    display: flex;
    cursor: pointer;
}
.Page_Client_Details .cnpj{
    width: 148px;
    text-align: center;
}
.Page_Client_Details .customer_since, .Page_Client_Details .list_name_page .phone{
    width: 110px;
    text-align: center;
}
.Page_Client_Details .list_name_page{
    border: 2px solid #ebebeb;
    border-radius: 6px;
    padding: 10px;
    margin-top: 30px;
}
.Page_Client_Details .list_name_page .date_nota{
    width: 138px;
}
.Page_Client_Details .state{
    width: 58px;
    text-align: center;
}

@media only screen and (max-width: 500px) {
    .Page_Client_Details .cnpj{
        width: calc(100% - 24px);
    }
    .Page_Client_Details .customer_since, .Page_Client_Details .list_name_page .phone{
        width: calc(100% - 24px);
    }
    .Page_Client_Details .div_delete{
        flex-grow: unset;
    }
    .Page_Client_Details .td_hide{
        display: none;
    }
}