.Page_Client{
    width: 100%;
    height: auto;
    padding-bottom: 70px;
}

@media only screen and (max-width: 720px) {
    .Page_Client .td_hide_1{
        display: none;
    }
}
@media only screen and (max-width: 500px) {
    .Page_Client .td_hide_2{
        display: none;
    }
}