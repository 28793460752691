.PopUp .historic {
    width: 800px;
}

.PopUp .historic .btn_alt{
    width: auto;
    padding: 8px 10px;
    background-color: #033A4C;
    cursor: pointer;
    color: #ededed;
    text-transform: uppercase;
    font-family: 'Inter';
    border-radius: 6px;
}
.PopUp .historic .btn_alt:hover{
    background-color: #07779c;
}
@media only screen and (max-width: 830px) {
    .PopUp .historic{
        width: 90%;
    }
}