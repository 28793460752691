.Page_Process {
    width: 100%;
    height: auto;
    padding-bottom: 70px;
}

@media only screen and (max-width: 500px) {
    .Page_Process .td_hide{
        display: none;
    }
}