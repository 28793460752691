.Page_Process_Details{
    padding-bottom: 70px;
}
.Page_Process_Details .date_document{
    width: 142px;
}
.Page_Process_Details .process{
    background: #033a4c;
    border: none;
    font-size: 15px;
    padding: 10px 50px;
}
.Page_Process_Details .year{
    width: 60px;
    text-align: center;
}
.Page_Process_Details .month{
    width: 80px;
    text-align: center;
}
.Page_Process_Details .div_btn_process{
    width: 100%;
}
.Page_Process_Details .div_btn_process .process{
    width: 100%;
}

@media only screen and (max-width: 500px) {
}